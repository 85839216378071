.App {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

hr {
  border: 4px solid rgb(88, 96, 89);
  border-radius: 4px;
  width: 98%;
}

.EducationHeader {
  font-family: 'Lato', arial, sans-serif;
  font-size: 25px;
  margin-left: 25px;
}

.WorkExperience {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.ProjectTitle {
  font-family: 'Lato', arial, sans-serif;
  font-size: 35px;
  margin-left: 20px;
  margin-top: 20px;
}

.ProjectFunctionalityList {
  font-family: 'Lusitana', Georgia, serif;
  color: rgb(88, 96, 89);
  font-size: 20px;
  margin-left: 20px;
  list-style-position: inside;
}

.ProjectFunctionalityTitle {
  font-family: 'Lusitana', Georgia, serif;
  font-size: 28px;
  margin-left: 20px;
}

.ProjectOverview {
  font-family: 'Lusitana', Georgia, serif;
  text-align: center;
  font-size: 20px;
  margin-left: 40px;
  margin-right: 40px;
}

.ProjectAssetCarousel {
  background-color: rgb(88, 96, 89);
  margin-bottom: 20px;
}

.Headshot {
  width: 30%;
}

.HelloTitle {
  font-family: 'Lato', arial, sans-serif;
  font-size: 35px;
  text-align: center;
  margin-top: 20px;
}

.HelloText {
  font-family: 'Lusitana', Georgia, serif;
  color: rgb(88, 96, 89);
  font-size: 20px;
}

.HelloContainer {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  justify-content: space-evenly;
  align-items: center;
}

.HelloContainerText {
  flex-basis: 50%;
  text-align: center;
}

.ContactText {
  font-family: 'Lusitana', Georgia, serif;
  color: rgb(88, 96, 89);
  font-size: 20px;
  margin-left: 20px;
  margin-top: 20px;
}

.Mobile {
  font-family: 'Lusitana', Georgia, serif;
  color: rgb(88, 96, 89);
  font-size: 20px;
  position: absolute;
  top: 50%;
  text-align: center;
}